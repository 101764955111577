<template>
  <!-- <v-list-item color="grey darken-3" :key="idx" :to="{ name: 'app.dashboard', params: { regId: regId } }"> -->
  <v-list-item class="mb-15">
    <template>
      <v-list-item-content>
        <!-- <div :class="[$vuetify.breakpoint.smAndDown ? '' : 'd-flex']" style="width: 100%"> -->
        <div style="width: 100%; display: grid; grid-template-columns: 50px 1fr">
          <div style="width: 50px" class="mr-4">
            <v-icon color="mainRed" size="40">
              mdi-car
            </v-icon>
          </div>
          <div
            style="display: grid;"
            :style="$vuetify.breakpoint.smAndDown ? 'grid-template-columns: 1fr' : 'grid-template-columns: 1fr 1fr'"
          >
            <div>
              <v-list-item-title class="mb-0 text-h5">{{ ymm }}</v-list-item-title>
              <v-list-item-subtitle class="mb-4">
                {{ timeStartedText }} 
              </v-list-item-subtitle>
              <v-list-item-title class="mb-0 text-h10">
<div class="gotham-font mt-8 mainRed--text">
    <b>{{ cpDeadlineText1}}</b><br><br>
    <b>{{ cpDeadlineText1a}}</b><br><br>
    <b>{{ cpDeadlineText2}}</b>
</div>

<div class="gotham-font mt-8">

 <v-list-item-title class="mb-0 text-h10">Status:
                {{ status === appIncompleteStatus ? 'Not Submitted' : status }}
              </v-list-item-title>
</div>
                              </v-list-item-title>

            </div>

            
            <br> <br>

            <!-- <div :class="$vuetify.breakpoint.smAndDown ? 'text-left' : 'text-right'" > 
              <v-list-item-title class="mb-0 text-h10">Status:
                {{ status === appIncompleteStatus ? 'Not Submitted' : status }}
              </v-list-item-title>
              <v-list-item-subtitle class="mb-10">status</v-list-item-subtitle>
            </div> -->


          </div>
        </div>

        <v-divider></v-divider>

        <div :class="$vuetify.breakpoint.smAndDown ? 'mt-4 pa-0' : 'px-10 py-4'">
          <!-- next steps text -->
          <v-card
            flat
            v-if="status !== transactionCompleteStatus"
            class="pa-0 mb-4"
            :class="this.$vuetify.theme.dark ? 'grey darken-1' : 'grey lighten-3'"
          >
            <v-card-text class="text-body-1" v-html="getHtml(nextStepsText)"></v-card-text>
          </v-card>

          <div
            style="display: grid; gap: 20px"
            :style="$vuetify.breakpoint.smAndDown ? 'grid-template-columns: 1fr' : 'grid-template-columns: 1fr 250px'"
          >
            <!-- app step status -->
            <div style="max-width: 400px;">
              <v-simple-table class="registrationTable">
                <tr>
                  <td class="pl-2">Application Submitted</td>
                  <td class="pr-2">
                    <StatusIcon :complete="appHasBeenSubmitted"></StatusIcon>
                  </td>
                </tr>
                <tr>
                  <td class="pl-2">Cost Breakdown Reviewed</td>
                  <td class="pr-2">
                    <StatusIcon :complete="costBreakdownHasBeenViewed"></StatusIcon>
                  </td>
                </tr>
                <tr>
                  <td class="pl-2">Payment Method Selected</td>
                  <td class="pr-2">
                    <StatusIcon :complete="paymentMethodHasBeenSelected"></StatusIcon>
                  </td>
                </tr>

  <tr>
                  <td class="pl-2">Documents Needed</td>
                  <td class="pr-2">
                    <StatusIcon :complete="paymentMethodHasBeenSelected"></StatusIcon>
                  </td>
                </tr>

                  <tr>
                  <td class="pl-2">FAQ</td>
                  <td class="pr-2">
                    <StatusIcon :complete="paymentMethodHasBeenSelected"></StatusIcon>
                  </td>
                </tr>

                <tr>
                  <td v-if="paymentType" class="pl-6 grey--text" colspan="2">&bull; {{ paymentType }}</td>
                </tr>
                <tr>
                  <td class="pl-2">Documents Received</td>
                  <td class="pr-2">
                    <StatusIcon :complete="docsHaveBeenReceived"></StatusIcon>
                  </td>
                </tr>
                <tr>
                  <td class="pl-2">Documents Approved</td>
                  <td class="pr-2">
                    <StatusIcon :complete="docsHaveBeenApproved"></StatusIcon>
                  </td>
                </tr>

                <tr style="height: 10px">
                  <td><hr /></td>
                  <td><hr /></td>
                </tr>
                <tr>
                  <td class="pl-2">Registration Cost</td>
                  <td class="pr-2 text-right">{{ (registrationAmount ? registrationAmount : 0) | currency }}</td>
                </tr>
                <tr>
                  <td class="pl-2">Payment Received</td>
                  <td class="text-right pr-2">{{ (totalReceived ? totalReceived : 0) | currency }}</td>
                </tr>
                <tr style="height: 10px">
                  <td><hr /></td>
                  <td><hr /></td>
                </tr>
                <tr>
                  <td :class="getBalanceClass" class="pl-2">Balance Due</td>
                  <td :class="getBalanceClass" class="pr-2 text-right">{{ (registrationAmount - totalReceived) | currency }}</td>
                </tr>
              </v-simple-table>
            </div>

            <!-- action buttons -->
            <div :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : 'ma-0'">
              <div class="mb-2">
                <v-btn
                  block
                  :class="this.$vuetify.theme.dark ? 'white mainBlack--text' : 'success'"
                  elevation="0"
                  :to="{ name: 'app.registration.application', params: { regId: regId } }"
                >
                  <v-icon class="mr-2">mdi-text-box-outline</v-icon>Application
                </v-btn>
              </div>
              <div class="mb-2">
                <v-btn
                  block
                  :class="[this.$vuetify.theme.dark ? 'white mainBlack--text' : 'success']"
                  :disabled="hideCostBreakdownBtn"
                  :to="{ name: 'app.registration.costBreakdown', params: { regId: regId } }"
                >
                  <v-icon class="mr-2">mdi-currency-usd</v-icon>Cost Breakdown
                </v-btn>
              </div>

              <div class="mb-2">
                <v-btn
                  block
                  :class="[this.$vuetify.theme.dark ? 'white mainBlack--text' : 'success']"
                  :disabled="hidePaymentMethodBtn"
                  :to="{ name: 'app.registration.paymentDetails', params: { regId: regId } }"
                >
                  <v-icon class="mr-2">mdi-playlist-check</v-icon>Payment Method
                </v-btn>
              </div>

                <div class="mb-2">
                <v-btn
                  block
                  :class="[this.$vuetify.theme.dark ? 'white mainBlack--text' : 'success']"
                  :disabled="hidePaymentMethodBtn"
                  :to="{ name: 'app.documentsNeeded', params: { regId: regId } }"
                >
                  <v-icon class="mr-2">mdi-file-document-outline</v-icon>Documents Needed
                </v-btn>
              </div>


                <div class="mb-2">
                <v-btn
                  block
                  :class="[this.$vuetify.theme.dark ? 'white mainBlack--text' : 'success']"
                  :disabled="hidePaymentMethodBtn"
                  :to="{ name: 'app.faq', params: { regId: regId } }"
                >
                  <v-icon class="mr-2">mdi-help-circle-outline</v-icon>FAQ
                </v-btn>
              </div>

              <div class="mb-2">
                <v-btn
                  block
                  target="_blank"
                  :class="[this.$vuetify.theme.dark ? 'white mainBlack--text' : 'success']"
                  :href="fedExTrackingUrl"
                  :disabled="hideFedExButton"
                  @click.native.prevent="nav(fedExTrackingUrl)"
                >
                  <v-icon class="mr-2">mdi-credit-card-marker-outline</v-icon>
                  FedEx Tracking
                </v-btn>
              </div>
              <div class="mb-2">
                <v-btn
                  block
                  href=""
                  target="_blank"
                  :class="[this.$vuetify.theme.dark ? 'white mainBlack--text' : 'success']"
                  :disabled="hideFedExButton"
                  @click.native.prevent="getReceipt(regId)"
                >
                  <v-icon class="mr-2">mdi-receipt</v-icon>
                  Receipt
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </v-list-item-content>
    </template>
  </v-list-item>
</template>

<script>
import moment from 'moment';
const __fedExUrlBase = 'https://www.fedex.com/fedextrack/?trknbr=';

export default {
  name: 'RegistrationItem',
  created: () => {},
  components: {
    StatusIcon: () => import('./StatusIcon'),
  },
  props: {
    status: {
      type: String,
      required: true,
      default: '',
    },
    registrationAmount: {
      type: Number,
      required: false,
      default: 0,
    },
    costBreakdownDate: {
      type: String,
      required: false,
      default: '',
    },
    paymentMethodDate: {
      type: String,
      required: false,
      default: '',
    },
    clientTrackingNumber: {
      type: String,
      required: false,
      default: '',
    },
    dateCreated: {
      type: String,
      required: true,
    },
    regId: {
      type: Number,
      required: true,
    },
    ymm: {
      type: String,
      // required: true,
    },
    cpDeadline: {
      type: String,
      // required: true,
    },
    totalReceived: {
      type: Number,
      required: false,
      default: 0,
    },
    cpRequestSubmittedOn: {
      type: String,
      required: false,
      default: '',
    },
    docsReceived: {
      type: String,
      required: false,
      default: '',
    },
    docsApproved: {
      type: String,
      required: false,
      default: '',
    },
    paymentType: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: () => ({
    notUpdatedDate: '1999-01-01T00:00:00.000Z',
    appIncompleteStatus: 'Draft',
    platesShippedStatus: 'Plates/Sticker Shipped',
    transactionCompleteStatus: 'Transaction Completed',
    toggle_exclusive: false,
  }),
  methods: {
    nav(url) {
      window.open(url, '_blank');
    },
    getReceipt(regId) {
      this.$store
        .dispatch('registration/getReceiptDownloadUrl', regId)
        .then(res => {
          window.open(res, '_blank');
        })
        .catch(err => {
          console.log('err: ', err);
        });
    },
    getFormattedDate(dateString) {
      return moment(String(dateString)).format('MM/DD/YYYY hh:mm');
    },
    getHtml(text) {
      return `<u>NEXT STEPS</u>: ${text}`;
    },
    getBalanceDiff: function() {
      const diff = Number(this.totalReceived - this.registrationAmount);
      return diff;
    },
  },
  computed: {
    appHasBeenSubmitted: function() {
      return this.cpRequestSubmittedOn ? true : false;
      // return this.getFormattedDate(this.cpRequestSubmittedOn);
    },
    docsHaveBeenReceived: function() {
      return this.docsReceived ? true : false;
    },
    docsHaveBeenApproved: function() {
      return this.docsApproved ? true : false;
    },
    costBreakdownHasBeenViewed: function() {
      return this.costBreakdownDate !== this.notUpdatedDate ? true : false;
    },
    paymentMethodHasBeenSelected: function() {
      return this.paymentMethodDate !== this.notUpdatedDate ? true : false;
    },
    paymentHasBeenReceived: function() {
      return this.totalReceived > 0 ? true : false;
    },
    hideCostBreakdownBtn: function() {
      return this.appHasBeenSubmitted && this.registrationAmount ? false : true;
    },
    hidePaymentMethodBtn: function() {
      return this.appHasBeenSubmitted && this.registrationAmount && this.costBreakdownHasBeenViewed ? false : true;
    },
    hideFedExButton: function() {
      return this.appHasBeenSubmitted && this.costBreakdownHasBeenViewed && this.paymentMethodHasBeenSelected && this.clientTrackingNumber
        ? false
        : true;
    },
    fedExTrackingUrl() {
      return __fedExUrlBase + this.clientTrackingNumber;
    },
    
    cpDeadlineText1: function() {
      return 'TO AVOID LATE FEES WE MUST HAVE PAYMENT AND REQUIRED PAPERWORK.';
    },

     cpDeadlineText1a: function() {
      return 'NOTE: $200 STATE EV FEE APPLIED AFTER 9/1/2023.  ';
    },

    cpDeadlineText2: function() {
      if (this.cpDeadline != null)
      return 'CUSTOMER DEADLINE IS ' + this.cpDeadline +'.';
      else 
      return 'CUSTOMER DEADLINE IS 20 DAYS AFTER DATE OF SALE.';
    },
    timeStartedText: function() {
      const date = (Date.now() - new Date(this.dateCreated).getTime()) / (60 * 60 * 24 * 1000);
      if (date < 1) {
        return 'started today';
      } else {
        return 'started ' + Math.ceil(date) + ' days ago';
      }
    },
    getBalanceClass: function() {
      const diff = this.getBalanceDiff();
      if (this.registrationAmount && diff) {
        return 'amtOwed';
      } else if (this.registrationAmount && diff === 0) {
        return 'amtSettled';
      } else {
        return '';
      }
    },
    nextStepsText: function() {
      if (this.appHasBeenSubmitted) {
        if (this.registrationAmount) {
          if (this.costBreakdownHasBeenViewed) {
            if (this.paymentMethodHasBeenSelected) {
              if (this.docsReceived) {
                if (this.docsApproved) {
                  if (this.paymentHasBeenReceived) {
                    if (this.getBalanceDiff() > 0) {
                      // payment is bigger than the amount requested
                      return 'Thank you for your payment.  A reconciliation of your account will be done once the white slip(receipt) is uploaded.  If a refund is due to you we will process that timely. Please check e-mail.';
                    } else if (this.getBalanceDiff() < 0) {
                      // the payment is short
                      return 'It seems the amount you paid did not match the registration cost.  Please submit the remaining amount or reach out to us if you have questions. Please check e-mail.';
                    } else if (this.getBalanceDiff() === 0) {
                      // the payment amount is correct

                      if (this.status === this.platesShippedStatus) {
                        // if status is plates shipped
                        return 'Your plates are on their way!  You can track them by clicking on "FedEx Tracking" below. Your title will be sent by the state and may arrive at a different time. Please check e-mail.';
                      } else {
                        // still processing order
                        return 'Your payment has been received and your registration is in process. Estimated time is based upon each individual county. Please be aware many county offices are experiencing delays and turn around times may be longer than normally predicted. Please check e-mail.';
                      }
                    } else {
                      // should not get here
                      return 'There seems to be a problem with your payment amount.  Please contact us, [err vadcr0350]';
                    }
                  } else {
                    return 'We are waiting to receive your payment. Please check e-mail.';
                  }
                } else {
                  return 'We are reviewing your documents and will be in contact with you soon. Please check e-mail.';
                }
              } else {
                return 'We are waiting to receive your documents. Please check e-mail.';
              }
            } else {
              return 'Click "Payment Method" to select the method you will use to submit your payment. Please check e-mail.';
            }
          } else {
            return 'Click "Cost Breakdown" to review your registration costs. Please check e-mail.';
          }
        } else {
          return 'We are calculating your registration cost breakdown and will have it available soon. Please check e-mail.';
        }
      } else {
        return 'Click "Application" to finish filling out your application. Please check e-mail.';
      }
    },
  },
};
</script>

<style scoped>
.test {
  background: red;
}
.registrationTable tr td:nth-child(2) {
  text-align: right;
}
.amtOwed {
  color: #333;
  background: #feeeb4;
}
.amtSettled {
  color: white;
  background: #4caf50;
}
</style>
